import React, { useState, useContext } from "react";
import PageHeader from "../components/PageHeader";
import { ThemeContext } from "../layout/Layout";
import InnerHeadr from "../components/InnerHeader";
import Marquee from "../components/Marquee";

const pageDetails = {
  head: {
    upper: "Let’s talk about",
    lower: "YOUR PROJECT",
  },
  bottom: {
    normal: "What are you waiting for?",
    italic: "Let's build your journey together",
  },
};

export default function Contact() {
  const theme = useContext(ThemeContext);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  return (
    <>
      <div className="w-screen relative z-20 min-h-screen flex flex-col items-start justify-center">
        <PageHeader title="Contact Us" />
        <InnerHeadr head={pageDetails.head} bottom={pageDetails.bottom} />
        <div className="w-screen pb-40 relative">
          <div className="container mx-auto">
            <div className="container px-8 md:px-0 mx-auto grid lg:grid-cols-2 grid-cols-1 gap-20">
              <div className="col-span-1 ">
                <h3 className="text-4xl md:text-5xl lg:text-6xl font-bold helv relative">
                  Contact TheoremStudio<span className="absolute mb-2 text-3xl">&reg;</span>
                </h3>
              </div>
              <div className="col-span-1 grid grid-cols-1 md:grid-cols-2 gap-10">
                <div className="col-span-1 flex flex-col items-start justify-start">
                  <div className="flex flex-col items-start justify-start">
                    <div className="flex w-32 h-32 border-2 border-black border-dashed items-center justify-center">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="40" height="40">
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M9.366 10.682a10.556 10.556 0 0 0 3.952 3.952l.884-1.238a1 1 0 0 1 1.294-.296 11.422 11.422 0 0 0 4.583 1.364 1 1 0 0 1 .921.997v4.462a1 1 0 0 1-.898.995c-.53.055-1.064.082-1.602.082C9.94 21 3 14.06 3 5.5c0-.538.027-1.072.082-1.602A1 1 0 0 1 4.077 3h4.462a1 1 0 0 1 .997.921A11.422 11.422 0 0 0 10.9 8.504a1 1 0 0 1-.296 1.294l-1.238.884zm-2.522-.657l1.9-1.357A13.41 13.41 0 0 1 7.647 5H5.01c-.006.166-.009.333-.009.5C5 12.956 11.044 19 18.5 19c.167 0 .334-.003.5-.01v-2.637a13.41 13.41 0 0 1-3.668-1.097l-1.357 1.9a12.442 12.442 0 0 1-1.588-.75l-.058-.033a12.556 12.556 0 0 1-4.702-4.702l-.033-.058a12.442 12.442 0 0 1-.75-1.588z" />
                      </svg>
                    </div>
                    <h5 className="text-4xl my-8">Call Us</h5>
                    <p className="text-xl">+92 300 795 44</p>
                  </div>
                  <div className="flex mt-20 flex-col items-start justify-start">
                    <div className="flex w-32 h-32 border-2 border-black border-dashed items-center justify-center">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="40" height="40">
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M12 20.9l4.95-4.95a7 7 0 1 0-9.9 0L12 20.9zm0 2.828l-6.364-6.364a9 9 0 1 1 12.728 0L12 23.728zM12 13a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 2a4 4 0 1 1 0-8 4 4 0 0 1 0 8z" />
                      </svg>
                    </div>
                    <h5 className="text-4xl my-8">Find Us</h5>
                    <p className="text-xl">2028 East Ben White Boulevard Suite 240-1989 Austin, Texas 78741</p>
                  </div>
                </div>
                <div className="col-span-1 flex flex-col items-start justify-start">
                  <div className="flex flex-col items-start justify-start">
                    <div className="flex w-32 h-32 border-2 border-black border-dashed items-center justify-center">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="40" height="40">
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M3 3h18a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm17 4.238l-7.928 7.1L4 7.216V19h16V7.238zM4.511 5l7.55 6.662L19.502 5H4.511z" />
                      </svg>
                    </div>

                    <h5 className="text-4xl my-8">Email Us</h5>
                    <p className="text-xl">hello@theoremstudio.com</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="container md:mt-48 my-16 md:mb-40 mx-auto border-b border-primary-100"></div>
            <div className="container px-8 md:px-0 mx-auto grid lg:grid-cols-2 grid-cols-1 gap-20">
              <div className="col-span-1 ">
                <h3 className="text-4xl md:text-5xl lg:text-6xl font-bold helv relative">Message Us</h3>
              </div>
              <div className="col-span-1 grid grid-cols-2 gap-10">
                <div className="col-span-2">
                  <p className="text-2xl">
                    Fill in the form and we will get back to you. Your information will be kept private and confidential and will be used by TheoremStudio® only. Fields marked with * must be filled before submiting the form.
                  </p>
                  <div className="w-full mt-10 flex flex-col items-start justify-start">
                    <label htmlFor="name" className="text-2xl focus:text-primary-100 my-2">
                      Name*
                    </label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      className="block w-full border-gray-600 p-0 py-2 bg-gray-100 text-xl uppercase border-0 border-b border-transparent light-white focus:border-primary-100 focus:ring-0"
                      placeholder="Jane Doe"
                    />
                  </div>
                  <div className="w-full my-5 flex flex-col items-start justify-start">
                    <label htmlFor="email" className="text-2xl my-2">
                      Email*
                    </label>
                    <input
                      type="text"
                      name="email"
                      id="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="block w-full p-0 py-2 bg-gray-100 text-xl uppercase border-0 border-b border-transparent light-white focus:border-primary-100 border-gray-600 focus:ring-0"
                      placeholder="jane@doe.com"
                    />
                  </div>
                  <div className="w-full my-5 flex flex-col items-start justify-start">
                    <label htmlFor="subject" className="text-2xl my-2">
                      Subject
                    </label>
                    <input
                      type="text"
                      name="subject"
                      id="subject"
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                      className="block w-full p-0 py-2 bg-gray-100 border-gray-600 text-xl capitalize border-0 border-b border-transparent light-white focus:border-primary-100 focus:ring-0"
                      placeholder="Subject"
                    />
                  </div>
                  <div className="w-full my-5  flex flex-col items-start justify-start">
                    <label htmlFor="message" className="text-2xl my-2">
                      Message
                    </label>
                    <textarea
                      rows={6}
                      type="text"
                      name="message"
                      id="message"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      className="block w-full p-0 py-2 bg-gray-100 border-gray-600 text-xl border-0 border-b border-transparent light-white focus:border-primary-100 focus:ring-0"
                      placeholder="Type here ..."
                    />
                  </div>
                  <button
                    className={
                      theme === "dark"
                        ? "bg-white text-black mt-10 hover:bg-primary-100 hover:text-black transition-all duration-300 rounded-3xl py-3 text-2xl uppercase px-8"
                        : "bg-black text-white mt-10 hover:bg-primary-100 hover:text-black transition-all duration-300 rounded-3xl py-3 text-2xl uppercase px-8"
                    }
                  >
                    Send Message
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Marquee cont="About US" />
      </div>
    </>
  );
}
