import React, { useContext } from "react";
import { ThemeContext } from "../layout/Layout";

export default function Marquee({ cont }) {
  const theme = useContext(ThemeContext);

  return (
    <div className="my-2 py-10 overflow-hidden relative w-screen ">
      <div class="marquee">
        <div class="marquee__inner helv uppercase transition-all duration-200 hover:text-primary-100 cursor-pointer" aria-hidden="true">
          <span> {cont} </span>
          <span> {cont} </span>
          <span> {cont} </span>
          <span> {cont} </span>
        </div>
      </div>
    </div>
  );
}
